<template>
  <v-app>
    <v-main app class="grey lighten-3 d-flex align-center">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'LoginLayout'
}
</script>
